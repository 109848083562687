import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import RolesGuard from "../utils/route-guard/RolesGuard";
import { Navigate } from "react-router-dom";

const OriginationPage = Loadable(
  lazy(() => import("sections/origination/OriginationPage"))
);
const FinDistressWizard = Loadable(
  lazy(() => import("sections/findistress/FinDistressPage"))
);
const HomePage = Loadable(lazy(() => import("layout/MainLayout/HomePage")));

const InvoicePage = Loadable(
  lazy(() => import("sections/invoices/InvoicePage"))
);

const DirectDebitPage = Loadable(
  lazy(() => import("sections/direct-debit/DirectDebitPage"))
);

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/500"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction"))
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <RolesGuard roles={["signed_borrower"]}>
          <MainLayout />
        </RolesGuard>
      ),
      children: [
        {
          path: "/",
          element: <Navigate to="/home" replace />,
        },
        {
          path: "/home",
          element: (
            <RolesGuard roles={["signed_borrower"]}>
              <HomePage />
            </RolesGuard>
          ),
        },
        {
          path: "/findistress",
          element: (
            <RolesGuard roles={["signed_borrower"]}>
              <FinDistressWizard />
            </RolesGuard>
          ),
        },
        {
          path: "/invoices",
          element: (
            <RolesGuard roles={["signed_borrower"]}>
              <InvoicePage />
            </RolesGuard>
          ),
        },
        {
          path: "/direct-debit",
          element: (
            <RolesGuard roles={["signed_borrower"]}>
              <DirectDebitPage />
            </RolesGuard>
          ),
        }
      ],
    },
    {
      path: "/application",
      element: (
        <RolesGuard roles={["borrower"]}>
          <OriginationPage />
        </RolesGuard>
      ),
    },
    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
  ],
};

export default MainRoutes;
